import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../StateHandler/AppProvider";
import { useToggleNode } from "../utils/util";

export const SelectedElementRow = ({ item }) => {
  const { state, dispatch } = useContext(AppContext);
  const [code, description, key] = item;

  const { toggleNodeMutation, LoadingBackdrop } = useToggleNode(dispatch);

  const handleToggle = () => {
    toggleNodeMutation.mutate({
      token: state.token,
      currentCheckedKeys: state.checkedKeys,
      key: key,
      checked: false,
    });
  };

  return (
    <Box
      sx={{
        p: 0,
        pl: 2,
        pr: 1,
        display: "flex",
        justifyContent: "space-between",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          boxSizing: "border-box",
          justifyContent: "flex-start",
          width: "92%",
        }}
      >
        <Typography display="inline" fontWeight="fontWeightBold">
          {code}
        </Typography>
        <Typography
          display="inline"
          fontWeight="fontWeightLight"
          sx={{
            pl: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          noWrap={true}
        >
          {description}
        </Typography>
      </Box>
      <IconButton
        sx={{
          color: "white",
          padding: 0,
          margin: 0,
          "&:hover": { color: "red" },
        }}
        onClick={handleToggle}
        disabled={toggleNodeMutation.isLoading}
      >
        <DeleteIcon />
      </IconButton>
      {toggleNodeMutation.isError && (
        <Typography color="error" variant="caption">
          Error: {toggleNodeMutation.error.message}
        </Typography>
      )}
    </Box>
  );
};
