import { Box } from "@mui/material";
import { LoadingElementShort } from "../utils/constants";
import { TreeNode } from "../tree/TreeNode";

export const ChildrenElement = ({ childKeys }, index) => {
  return (
    <Box sx={{ ml: 4, mt: 0.5 }} key={index}>
      <div>
        {childKeys
          ? childKeys.map((node, index) => <TreeNode key={index} node={node} />)
          : LoadingElementShort}
      </div>
    </Box>
  );
};
