import React, { useContext } from "react";
import { Box, ListItem, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import { AppContext } from "../StateHandler/AppProvider";
import { getKeysToExpandForContext, useToggleNode } from "../utils/util";

const CustomListItem = ({ hidden, item, index, checked }) => {
  const { state, dispatch } = useContext(AppContext);
  const { toggleNodeMutation, LoadingBackdrop } = useToggleNode(dispatch);

  if (hidden) {
    return null;
  }

  const getTextColor = () => "black";

  const darkGreen = "#006400";

  const { key, vocabulary, code, description, has_children } = item;

  const handleToggle = () => {
    toggleNodeMutation.mutate({
      token: state.token,
      currentCheckedKeys: state.checkedKeys,
      key: key,
      checked: true,
    });
  };

  const handleOpenInContext = () => {
    const expandedKeysToOpen = getKeysToExpandForContext(key, has_children);
    dispatch({ type: "SET_EXPANDED_KEYS", payload: expandedKeysToOpen });
    dispatch({
      type: "SET_SELECTED_TREE_CATEGORY",
      payload: key.split("$").shift(),
    });
    dispatch({ type: "SET_HIGHLIGHTED_KEYS", payload: [key] });
  };

  return (
    <ListItem
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 0,
        "&:hover .changeColorOnHover": {
          color: checked ? "white" : darkGreen,
          fontWeight: "bold",
        },
        backgroundColor: checked ? darkGreen : "inherit",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          overflow: "hidden",
        }}
        onClick={handleToggle}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              ml: 1,
              backgroundColor: "grey",
              color: "white",
              padding: "2px 6px",
              borderRadius: "4px",
              fontSize: "0.65rem",
              fontWeight: "bold",
              marginRight: "8px",
              minWidth: "24px",
              textAlign: "center",
            }}
          >
            {vocabulary}
          </Box>
          <Typography
            variant="body1"
            className="changeColorOnHover"
            sx={{
              fontWeight: "bold",
              color: getTextColor(),
              display: "flex",
              alignItems: "center",
            }}
          >
            {code}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            ml: 2,
            overflow: "hidden",
          }}
        >
          <Tooltip title={description} placement="right" arrow>
            <Typography
              variant="body2"
              className="changeColorOnHover"
              sx={{
                color: getTextColor(),
                mr: 2,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {description}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Tooltip title="Open in Context" placement="left" arrow>
        <IconButton
          size="small"
          sx={{ mr: 1, color: "black" }}
          onClick={handleOpenInContext}
        >
          <LaunchIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {toggleNodeMutation.isLoading && (
        <Typography variant="caption" color="text.secondary">
          Loading...
        </Typography>
      )}
      {toggleNodeMutation.isError && (
        <Typography variant="caption" color="error">
          Error: {toggleNodeMutation.error.message}
        </Typography>
      )}
      <LoadingBackdrop />
    </ListItem>
  );
};

export default CustomListItem;
