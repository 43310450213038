import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid, Typography } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../StateHandler/AppProvider";
import { SettingsComponent } from "./SettingsComponent";
import { SelectedElementRow } from "./SelectedElementRow";
import { TREE_VOCABULARIES } from "../utils/constants";
import { toast, Bounce } from "react-toastify";
import { url_base } from "../utils/constants";

export default function LeftDrawerComponent(props) {
  const { state, dispatch } = useContext(AppContext);

  const { flagSettings, formattedCodes, checkedKeys } = state;

  const { user, logout } = useAuth0();

  const copyTQL = async (checkedKeys, configFlags) => {
    if (checkedKeys.length === 0) {
      toast.warn("No codes to copy!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    fetch("/api/output/generate_tql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${state?.token}`,
      },
      body: JSON.stringify({
        keys: checkedKeys,
        flags: configFlags,
      }),
    })
      .then((tql) => tql.json())
      .then((tql) => {
        console.log(tql);
        navigator.clipboard.writeText(tql).catch((e) => console.log(e));
        // navigator.clipboard.writeText(tql).catch(e => console.log(e))
      })
      .then(() => {
        toast.success("TQL Copied!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const copyCodes = async (keys) => {
    if (keys.length === 0) {
      toast.warn("No codes to copy!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    fetch(`https://${url_base}/api/output/generate_descriptions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${state?.token}`,
      },
      body: JSON.stringify({
        keys: keys,
      }),
    })
      .then((contents) => contents.json())
      .then((contents) => {
        console.log(contents);
        navigator.clipboard.writeText(contents).catch((e) => console.log(e));
        // navigator.clipboard.writeText(tql).catch(e => console.log(e))
      })
      .then(() => {
        toast.success("Codes Copied!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
      height={"100vh"}
      backgroundColor={"grey.400"}
    >
      {/* Title */}
      <Grid>
        <Typography variant="h4" noWrap sx={{ padding: 2 }}>
          Code Navigator
        </Typography>
      </Grid>
      <Grid width={"100%"}>
        <Typography variant="h5" noWrap sx={{ padding: 2 }}>
          Selected Terms
        </Typography>
      </Grid>
      <Grid
        width={"100%"}
        flex={1}
        overflow="auto" // This will become scrollable if the content exceeds its height
      >
        <Box sx={{ pl: 1 }} height={"100%"} width={"100%"}>
          {TREE_VOCABULARIES.map((vocab) => {
            const codes = formattedCodes[vocab];
            if (!codes) {
              return null;
            }
            codes.sort((a, b) => a[0].localeCompare(b[0]));
            return (
              <div key={vocab}>
                <Box sx={{ pt: 2 }}>
                  <Typography variant={"h4"}>{vocab}</Typography>
                </Box>
                <>
                  <Box width={"100%"}>
                    {codes.map((el, index) => {
                      return <SelectedElementRow item={el} key={index} />;
                    })}
                  </Box>
                </>
              </div>
            );
          })}
        </Box>
      </Grid>

      <Grid
        sx={{
          width: "100%", // Ensure the box takes up the full width of the parent container
          py: 2,
          px: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Allow the box to grow and shrink as needed
            backgroundColor: "grey.400", // Set the background color to match the ListSubheader
          }}
        >
          {/* First Line */}
          <Box sx={{ display: "flex", mb: 1 }}>
            <Button
              variant="contained"
              onClick={() => copyTQL(checkedKeys, flagSettings)}
              sx={{ width: "calc(50% - 4px)", mr: "8px" }} // Adjust for spacing
            >
              Copy TQL
            </Button>
            <Button
              variant="contained"
              onClick={() => copyCodes(checkedKeys)}
              sx={{ width: "calc(50% - 4px)" }} // Adjust for button spacing
            >
              Copy Codes
            </Button>
          </Box>

          {/* Second Line */}
          <Box sx={{ display: "flex", mb: 1 }}>
            <SettingsComponent {...props} />
            <Button
              variant="contained"
              color="error"
              onClick={() => dispatch({ type: "CLEAR_ALL" })}
              sx={{ width: "calc(50% - 4px)" }} // Consistent with first line
            >
              Clear All
            </Button>
          </Box>
          <Button
            variant="contained"
            color="error"
            onClick={logout}
            // sx={{width: 'calc(50% - 4px)'}} // Consistent with first line
          >
            Log Out {user?.given_name}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
