import React, { useContext, useEffect, useState } from "react";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import { Box, Grid, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { CustomTextField } from "./CustomTextField";
import { AppContext } from "../StateHandler/AppProvider";
import { search } from "../utils/util";
import CustomListItem from "./CustomListItem";
import _ from "lodash";

export default function SearchComponent() {
  const { state, dispatch } = useContext(AppContext);
  const [isSearching, setIsSearching] = useState(false);

  const {
    searchValue,
    searchResults,
    vocabsToSearch,
    checkedKeys,
    showSelectedTerms,
  } = state;

  const searchResultLengths = 100;
  const isFirstPageSearch =
    searchResults && searchResults.length === searchResultLengths;

  const setSearchResults = (results) => {
    console.log(results);
    dispatch({ type: "SET_SEARCH_RESULTS", payload: results });
  };

  const maxScore =
    searchResults?.reduce((max, item) => Math.max(max, item._score), 0) ??
    undefined;

  const checkboxes = Object.keys(vocabsToSearch).map((key) => ({
    name: key,
    value: vocabsToSearch[key],
  }));

  // Debounced search function
  const debouncedSearch = _.debounce(async (query, vocabularies) => {
    setIsSearching(true);

    const payload = {
      query: query,
      size: isFirstPageSearch ? searchResultLengths : searchResultLengths,
      from: 0,
      Vocabulary: vocabularies,
    };

    try {
      const results = await search(state, payload);
      console.log(results);
      setIsSearching(false);
      setSearchResults(results);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setIsSearching(false);
      setSearchResults([]);
    }
  }, 300); // 300ms delay

  useEffect(() => {
    const vocabularies = Object.keys(vocabsToSearch).filter(
      (key) => vocabsToSearch[key]
    );

    if (!searchValue || searchValue === "" || vocabularies.length === 0) {
      setIsSearching(false);
      setSearchResults([]);
      return;
    }
    setIsSearching(true);

    debouncedSearch(searchValue, vocabularies);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue, vocabsToSearch, isFirstPageSearch]);

  const SearchResultElement = () => {
    // Currently Searching
    // Returns list of 30 Skeletons
    if (isSearching) {
      return (
        <Grid item xs={10} width={"70%"} overflow={"auto"} sx={{ pr: 1 }}>
          {Array.from({ length: 15 }).map((_, index) => (
            <Skeleton key={index} variant="text" sx={{ fontSize: "1rem" }} />
          ))}
        </Grid>
      );
    }

    // No Vocabularies Selected
    const vocabularies = Object.keys(vocabsToSearch).filter(
      (key) => vocabsToSearch[key]
    );
    if (vocabularies.length === 0) {
      return (
        <>
          <Typography>
            Please Select at least one vocabulary to Search.{" "}
          </Typography>
        </>
      );
    }

    // No Search Value
    if (!searchValue || searchValue === "") {
      return (
        <>
          <Typography>Enter a search term to begin.</Typography>
        </>
      );
    }

    // No Results
    if (searchResults && searchResults.length === 0) {
      return (
        <>
          <Typography>No results found.</Typography>
        </>
      );
    }

    // Null Search Results (init)
    if (!searchResults) {
      return <></>;
    }

    // Results
    return (
      <>
        <Grid item xs={10} width={1} overflow={"auto"} sx={{ pr: 1 }}>
          {" "}
          <List>
            {searchResults
              .filter((item) => {
                return (
                  showSelectedTerms ||
                  !checkedKeys.some((checkedKey) => checkedKey === item["key"])
                );
              })
              .map((item, index) => (
                <CustomListItem
                  key={index}
                  item={item}
                  maxScore={maxScore}
                  checked={checkedKeys.includes(item["key"])}
                />
              ))}
          </List>
          {isFirstPageSearch && <Button>Load More</Button>}
        </Grid>
      </>
    );
  };

  // const selectAll = () => {
  //     let newCheckedKeys = searchResults.map((item) => item['_source']['Key'])
  //     handleMultipleChecks(newCheckedKeys, checkedKeysWithData, indeterminateKeys, setCheckedKeysWithData, setIndeterminateKeys)
  //     // .forEach((key) => {
  //     //     handleCheck(null, key, checkedKeysWithData, indeterminateKeys, setCheckedKeysWithData, setIndeterminateKeys)
  //     // })
  // }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="start"
      // spacing={0}
      width={1}
      height={"100vh"}
      sx={{ p: 2 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", // Adjusted for spacing
          alignItems: "center",
          width: "100%", // Ensure the box spans the full width
          height: "fit-content",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4" sx={{ mb: 0, mr: 1 }}>
            Search
          </Typography>
        </Box>
        <Box>
          {/*<Button onClick={selectAll}*/}
          {/*        sx={{px: 2}}>Select All</Button>*/}
          {/*<FormControlLabel*/}
          {/*    control={<Switch checked={searchSkytale}*/}
          {/*                     onChange={(event) => setSearchSkytale(event.target.checked)}/>}*/}
          {/*    label="Skytale"*/}
          {/*    sx={{marginRight: 2}} // Adjust spacing as needed*/}
          {/*/>*/}
          {/*<Button*/}
          {/*    variant="contained"*/}
          {/*    sx={{mr: 3, mb: 1}}*/}
          {/*    // onClick={selectAll}*/}
          {/*>Select All</Button>*/}

          <FormControlLabel
            control={
              <Switch
                checked={showSelectedTerms}
                onChange={() =>
                  dispatch({ type: "TOGGLE_SHOW_SELECTED_TERMS" })
                }
              />
            }
            label="Show selected terms"
            sx={{ marginRight: 0 }} // Adjust spacing as needed
          />
        </Box>
      </Box>

      <CustomTextField />

      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        {checkboxes.map(({ name, value }) => (
          <FormControlLabel
            key={name}
            control={
              <Checkbox
                checked={value}
                onChange={() =>
                  dispatch({ type: "TOGGLE_VOCAB_SEARCH", payload: name })
                }
              />
            }
            label={name}
          />
        ))}
      </Box>
      {/*Render the Search results if isSearching is false*/}

      <SearchResultElement />
      {/*Additional Search-related content */}

      {/*<Box*/}
      {/*    backgroundColor="lightgrey"*/}
      {/*    position="absolute" bottom="0px" width="30%" p={2}*/}
      {/*    sx={{display: 'flex', justifyContent: "left", alignItems: 'left', my: 2}}*/}
      {/*    flexDirection={"column"}*/}
      {/*>*/}
      {/*    <div>*/}
      {/*        <Typography variant="h4">*/}
      {/*            Matching Phenotypes*/}
      {/*        </Typography>*/}
      {/*    </div>*/}
      {/*    {matchingPhenotypes.map((phenotype, index) => (*/}
      {/*        <div>*/}
      {/*            <Tooltip title={phenotype[1]}>*/}
      {/*                <Typography variant="body1">*/}
      {/*                    {phenotype[0]}*/}
      {/*                </Typography>*/}
      {/*            </Tooltip>*/}
      {/*        </div>*/}
      {/*        // <Typography key={index} variant="body2">*/}
      {/*        //     {phenotype[0]} ({phenotype[1]})*/}
      {/*        // </Typography>*/}
      {/*    ))}*/}
      {/*</Box>*/}
    </Grid>
  );
}
