import { url_base, VOCABULARY_TREES } from "./constants";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export const getStatusOfNode = (state, node) => {
  const { checkedKeys, highlightedKeys, searchResults, expandedKeys } = state;

  const nodeKey = node["key"];

  const isSelected = checkedKeys.includes(nodeKey);

  const isIndeterminate =
    !isSelected &&
    state.checkedKeys.some((checkedKey) => checkedKey.startsWith(nodeKey));

  const isHighlighted = highlightedKeys.includes(nodeKey);

  // const isParentOfSearchResult = searchResults.filter(searchKey => nodeData['AllChildren'].includes(searchKey)).length > 0

  const isSearchResult =
    searchResults
      ?.map((item) => item["key"])
      .filter((key) => key === nodeKey || nodeKey.startsWith(key)).length > 0;

  const isExpanded = expandedKeys.includes(nodeKey);

  if (isExpanded && !node["has_children"]) {
    console.log(node);
    throw Error("Can't expand or contract node without children");
  }

  if (isSelected && isIndeterminate) {
    throw Error();
  }

  return {
    isSelected,
    isExpanded,
    isIndeterminate,
    isHighlighted,
    isSearchResult,
  };
};

const getHeaders = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});

// Custom hook for fetching data with caching
export const useFetchWithCache = (url, state, dispatch, enabled) => {
  // enabled && console.log(url);

  return useQuery({
    queryKey: [url],
    staleTime: Infinity,
    queryFn: async () => {
      if (!state?.token) {
        throw new Error("No token provided");
      }

      const response = await fetch(url, {
        headers: getHeaders(state?.token),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (url === "/api/trees/level/2") {
        VOCABULARY_TREES.forEach((vocab) => {
          const selectedData = data.filter((el) => el.vocabulary === vocab);

          dispatch({
            type: `SET_${vocab}_TREE_LEVEL2_DATA`,
            payload: selectedData,
          });
        });
      }

      return data;
    },
    enabled: enabled,
  });
};

// Hook for fetching children
export const useFetchChildren = (key, state, dispatch, enabled = false) => {
  const url = `/api/trees/children?key=${key}`;

  return useFetchWithCache(url, state, dispatch, enabled);
};

// Hook for toggling node
export const useToggleNode = (dispatch) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleNodeMutation = useMutation({
    mutationFn: async ({ token, currentCheckedKeys, key, checked }) => {
      const response = await fetch(
        `https://${url_base}/api/trees/togglecheck`,
        {
          method: "POST",
          headers: getHeaders(token),
          body: JSON.stringify({ currentCheckedKeys, key, checked }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      // queryClient.setQueryData(["checkedKeys"], data.checkedKeys);
      // queryClient.setQueryData(["formattedCodes"], data.formattedCodes);
      dispatch({ type: "SET_CHECKED_KEYS", payload: data.checkedKeys });
      dispatch({ type: "SET_FORMATTED_CODES", payload: data.formattedCodes });
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const LoadingBackdrop = () => (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return { toggleNodeMutation, LoadingBackdrop };
};

export function getSynonymStr(synonyms) {
  let synonymStr;
  if (typeof synonyms === "undefined") {
    synonymStr = "";
  } else {
    if (Object.keys(synonyms).length !== 0 && synonyms.length !== 1) {
      synonymStr = synonyms.join("\n");
    } else {
      synonymStr = "<no synonyms>";
    }
  }

  return synonymStr;
}

export function getKeysToExpandForContext(Key, has_children) {
  let keys = Key.split("$");
  let expandedKeys = [];

  for (let i = 0; i < keys.length; i++) {
    expandedKeys.push(keys.slice(0, i + 1).join("$"));
  }

  if (!has_children) {
    expandedKeys.pop();
  }

  return expandedKeys;
}

export const mapCodesToKeys = async (dispatch, state, codes) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${state?.token}`,
    },
    body: JSON.stringify({
      codes: codes,
    }),
  };
  fetch(`https://${url_base}/api/trees/mapCodesToKeys`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);

      dispatch({ type: "SET_CHECKED_KEYS", payload: data.checkedKeys });
      dispatch({ type: "SET_FORMATTED_CODES", payload: data.formattedCodes });
    });
};

export const search = async (state, payload, signal) => {
  return await fetch(`https://${url_base}/api/search/`, {
    signal,
    method: "POST",
    headers: getHeaders(state?.token),
    credentials: "include",
    body: JSON.stringify(payload),
  })
    .then(async (response) => {
      return await response.json();
    })
    .catch((err) => {
      if (err.name === "AbortError") {
        // console.log('Fetch aborted');
      } else {
        console.error("Fetch error:", err);
      }
    });
};
