import React, {useState} from 'react';
import {Button, Popover} from "@mui/material";


export const SettingsComponent = () => {
    const [anchorEl, setAnchorEl] = useState(null)


    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (<>
            <Button
                variant="contained"
                onClick={handleClick}
                sx={{width: 'calc(50% - 4px)', mr: '8px'}}
                color={"grey"}
            >Settings</Button>
            <Popover
                id={1234}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {/*<Box sx={{padding: 2}}>*/}
                {/*    {flagSettings.map(({key, label, enabled}) => (*/}

                {/*        <FormControlLabel*/}
                {/*            key={key}*/}
                {/*            control={*/}
                {/*                <Checkbox*/}
                {/*                    checked={flagSettings[key]}*/}
                {/*                    onChange={(e) => dispatch({*/}
                {/*                        type: "UPDATE_FLAG",*/}
                {/*                        payload: {key: key, value: e.target.checked}*/}
                {/*                    })}*/}
                {/*                    sx={{*/}

                {/*                        padding: '0px',*/}
                {/*                        margin: '0px 4px 0px 0px'*/}
                {/*                    }}*/}
                {/*                    disabled={!enabled}*/}
                {/*                />*/}
                {/*            }*/}
                {/*            label={<Typography component={"span"} variant="body2">{label}</Typography>}*/}
                {/*            sx={{*/}
                {/*                display: 'block',*/}
                {/*                margin: '4px 0',*/}
                {/*                justifyContent: 'space-between',*/}
                {/*            }}*/}
                {/*        />*/}

                {/*    ))}*/}
                {/*</Box>*/}
            </Popover>
        </>
    )
}