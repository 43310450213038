import React, { useContext } from "react";

import "../App.css";
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { AppContext } from "../StateHandler/AppProvider";

export function CustomTextField() {
  const { state, dispatch } = useContext(AppContext);

  const { searchValue, searchResults } = state;

  const numSearchResults = searchResults ? searchResults.length : 0;

  // useTraceUpdate(props);

  return (
    <TextField
      autoFocus
      fullWidth
      value={searchValue}
      onChange={(event) => {
        dispatch({ type: "SET_SEARCH_VALUE", payload: event.target.value });
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {/* Displaying the number of results */}
            {typeof numSearchResults === "number" && (
              <Typography style={{ marginRight: 8, color: "lightgray" }}>
                {numSearchResults} results
              </Typography>
            )}
            {/* Clear button */}
            {state.searchValue && (
              <IconButton onClick={() => dispatch({ type: "CLEAR_SEARCH" })}>
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
