import React from 'react';
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import {Button, Grid} from "@mui/material";
import {ToastContainer} from 'react-toastify';
import {TreeVisualizationContainer} from "./tree/TreeVisualizationContainer";
import SearchComponent from "./Search/SearchComponent";
import LeftDrawerComponent from "./LeftDrawerComponent/LeftDrawerComponent";
import {useAuth0} from "@auth0/auth0-react";
import {mapCodesToKeys, useFetchChildren} from "./utils/util";
import {AppContext} from "./StateHandler/AppProvider";
import {useContext} from "react";
import {useFetchWithCache} from "./utils/util";
import { url_base} from "./utils/constants";
// import { useQuery } from '@tanstack/react-query'




const App = () => {

    const {
        user,
        isAuthenticated,
        isLoading,
        loginWithPopup, 
        getAccessTokenSilently
    } = useAuth0();

    const {state, dispatch} = useContext(AppContext);

    const {data: level2Data, isLoading: level2Loading, isError: level2Error} = useFetchWithCache(
        `https://${url_base}/api/trees/level/2`,
        state, dispatch,
        true
    );
    
    const {data: icd9TopLevel} = useFetchChildren("ICD9$001-999.99", state, dispatch, true )
  
    const url = window.location.href;

    if (url.match(/access_denied|error/)) {
        return <div>Access Denied</div>
    }

    if(url.match(/codes\?.+/)){
        const toparse =  decodeURI(url.match(/codes\?(.+)/)[1])
        
        const spaceless = toparse.replaceAll(/\s+/g, "")        
    
        const codes = spaceless.split(",")
            .map((code) => { 

                const spl = code.toUpperCase().split('=')
                spl[1] = spl[1].replace(/"/g, '')
                return spl
            })
            
        mapCodesToKeys(dispatch, state, codes) 

    }

    const handlLoginin = async () => {
        await loginWithPopup({
                prompt: 'consent',

                authorizationParams: {
                    redirect_uri: window.location.origin,
                    audience: `https://codenavigator.pike00.com/api/`,
                    scope: "profile openid email"
                },
            }
        )
    }


    if ((!isAuthenticated || user === undefined) && !isLoading) {
        return (
            <Button onClick={handlLoginin}>Log In</Button>
        )
    } else if (isLoading) {
        return <div>Loading...</div>
    }

    if(!state?.token){        
        getAccessTokenSilently({
            authorizationParams: {
                audience: `https://codenavigator.pike00.com/api/`,
                },
        }).then(token =>  {
            dispatch({type: "SET_TOKEN", payload: token})        
        })
    }

  // Use the query results
    if (level2Loading) {
        return <div>Loading...</div>;
    }

    if (level2Error) {
        return <div>Error loading data</div>;
    }

    return (<>
            <Grid container sx={{height: '100vh'}} flexDirection={"row"}>
                <Grid
                    item
                    xs={2}
                >
                    <LeftDrawerComponent/>
                </Grid>

                <Grid
                    item
                    xs={6}
                >
                    <TreeVisualizationContainer/>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <SearchComponent/>

                </Grid>


            </Grid>

            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );

}

export default App