import React, { useContext, useEffect } from "react";
import { Checkbox, Grid, Typography } from "@mui/material";
import {
  getStatusOfNode,
  useToggleNode,
  useFetchChildren,
} from "../utils/util";
import { AppContext } from "../StateHandler/AppProvider";
import { ExpandedElement } from "../TreeNodeComponents/ExpandedElement";
import { ChildrenElement } from "../TreeNodeComponents/ChildrenElement";
import { LOAD_ON_HOVER } from "../utils/constants";
import { LoadingElementShort } from "../utils/constants";

export function TreeNode({ node }) {
  const { state, dispatch } = useContext(AppContext);
  const { key, description, code, has_children } = node;

  const {
    isSelected,
    isExpanded,
    isIndeterminate,
    isHighlighted,
    isSearchResult,
  } = getStatusOfNode(state, node);

  const { toggleNodeMutation, LoadingBackdrop } = useToggleNode(dispatch);

  const {
    data: children,
    isLoading: isLoadingChildren,
    error: childrenError,
  } = useFetchChildren(key, state, dispatch, isExpanded);

  const handleToggle = (e) => {
    toggleNodeMutation.mutate({
      token: state.token,
      currentCheckedKeys: state.checkedKeys,
      key: key,
      checked: e.target.checked,
    });
  };

  const handleMouseEnter = () => {
    if (has_children && LOAD_ON_HOVER) {
      // The useFetchChildren hook will handle the actual fetching
      // We just need to ensure it's enabled
      dispatch({
        type: "SET_EXPANDED_KEYS",
        payload: [...state.expandedKeys, key],
      });
    }
  };

  return (
    <>
      <Grid
        wrap="nowrap"
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="start"
        sx={{
          p: 0,
          pl: 1,
          fontWeight: isSelected || isIndeterminate ? "500" : "300",
          animation: isHighlighted ? "highlight 3s" : "none",
          transition: "opacity 3s ease-out",
        }}
        onMouseEnter={handleMouseEnter}
      >
        <ExpandedElement
          nodeKey={key}
          isExpanded={isExpanded}
          has_children={has_children}
          // children={children}
        />
        <Checkbox
          key={key}
          checked={isSelected}
          indeterminate={isIndeterminate}
          onChange={handleToggle}
          sx={{ p: 0 }}
        />
        <Typography
          noWrap
          sx={{
            p: 0,
            pl: 1,
            fontWeight: isSelected || isIndeterminate ? "500" : "300",
            backgroundColor: isSearchResult ? "lightyellow" : "transparent",
            color:
              isSearchResult && !(isSelected || isIndeterminate)
                ? "darkblue"
                : "black",
          }}
        >
          {code}: {description}
        </Typography>
      </Grid>
      {isExpanded &&
        (isLoadingChildren ? (
          // <Typography>Loading children...</Typography>
          <>{LoadingElementShort}</>
        ) : childrenError ? (
          <Typography color="error">
            Error loading children: {childrenError.message}
          </Typography>
        ) : (
          <ChildrenElement childKeys={children} />
        ))}
      {toggleNodeMutation?.isLoading && (
        <Typography>Updating selection...</Typography>
      )}
      {toggleNodeMutation?.isError && (
        <Typography color="error">
          Error updating selection: {toggleNodeMutation.error.message}
        </Typography>
      )}
      <LoadingBackdrop />
    </>
  );
}
