import React, {createContext,useReducer} from 'react';
import {FLAG_SETTINGS, VOCABULARY_TREES} from "../utils/constants";

// Create a context
export const AppContext = createContext();

export const initialState = {
    // Constants
    cptTreeLevel2Data: [],
    icd9TreeLevel2Data: [],
    icd10TreeLevel2Data: [],
    atcTreeLevel2Data: [],

    downloadedChildData: {},

    // Dynamic states
    expandedKeys: ["ICD9$001-999.99"],
    checkedKeys: [],
    formattedCodes: {},

    highlightedKeys: [],
    showSearchTermsInContext: true,
    candidatePhenotypes: [],

    // Tree Visualization
    selectedCategory: 'CPT',

    // Settings
    flagSettings: FLAG_SETTINGS,

    // Search
    vocabsToSearch: {
        CPT: false,
        ICD9: false,
        ICD10: false,
        ATC: false,
    },
    searchValue: '',
    searchResults: [],
    showSelectedTerms: false,
    isFirstPageSearch: true,
    config_convertToICD9: true,

    token: null
};


// Define the reducer
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_CPT_TREE_LEVEL2_DATA':            
            return {...state, cptTreeLevel2Data: action.payload};
        case 'SET_ICD9_TREE_LEVEL2_DATA':
            return {...state, icd9TreeLevel2Data: action.payload};
        case 'SET_ICD10_TREE_LEVEL2_DATA':
            return {...state, icd10TreeLevel2Data: action.payload};
        case 'SET_ATC_TREE_LEVEL2_DATA':
            return {...state, atcTreeLevel2Data: action.payload};

        case 'SET_DOWNLOADED_CHILD_DATA':
            return {...state, downloadedChildData: {...state.downloadedChildData, ...action.payload}};

        case 'SET_EXPANDED_KEYS':
            return {...state, expandedKeys: action.payload};
        case 'ADD_EXPANDED_KEY':
            return {...state, expandedKeys: [...state.expandedKeys, action.payload]};
        case 'REMOVE_EXPANDED_KEY':
            return {...state, expandedKeys: state.expandedKeys.filter(key => key !== action.payload)};

        case 'SET_CHECKED_KEYS':
            return {...state, checkedKeys: action.payload};
        case 'SET_FORMATTED_CODES':
            return {...state, formattedCodes: action.payload};

        case 'SET_HIGHLIGHTED_KEYS':
            return {...state, highlightedKeys: action.payload};


        case 'SET_SELECTED_TREE_CATEGORY':
            if (!VOCABULARY_TREES.includes(action.payload)) {
                throw new Error(`Invalid category: ${action.payload}`)
            }

            return {...state, selectedCategory: action.payload};
        case 'TOGGLE_SHOW_SEARCH_TERMS_IN_CONTEXT':
            return {...state, showSearchTermsInContext: !state.showSearchTermsInContext};

        // Search
        case 'SET_SEARCH_VALUE':
            if (action.payload === "") {
                return {...state, searchValue: action.payload, isFirstPageSearch: true, searchResults: []};
            } else {
                return {...state, searchValue: action.payload, isFirstPageSearch: true};
            }
        case 'CLEAR_SEARCH':
            return {...state, searchValue: '', searchResults: [], isFirstPageSearch: true};
        case 'TOGGLE_VOCAB_SEARCH':
            const vocabsToSearch = {...state.vocabsToSearch, [action.payload]: !state.vocabsToSearch[action.payload]}
            return {...state, vocabsToSearch: vocabsToSearch};
        case 'SET_SEARCH_RESULTS':
            return {...state, searchResults: action.payload};
        case 'TOGGLE_SHOW_SELECTED_TERMS':
            return {...state, showSelectedTerms: !state.showSelectedTerms};


        case 'SET_CANDIDATE_PHENOTYPES':
            return {...state, candidatePhenotypes: action.payload};

        case 'CLEAR_ALL':
            return Object.keys(initialState)
                .map(key => {
                    if (key.includes("Data")) {
                        return {[key]: state[key]}
                    } else {
                        console.log(key, initialState[key])

                        return {[key]: initialState[key]}
                    }
                }).reduce((acc, curr) => {
                    return {...acc, ...curr}
                })


        case 'UPDATE_FLAG':
            const newFlagSettings = state.flagSettings.map(flag => {
                if (flag.key === action.payload) {
                    return {...flag, enabled: !flag.enabled}
                } else {
                    return flag;
                }
            })
            return {...state, flagSettings: newFlagSettings};

        case 'SET_TOKEN':
            return {...state, token: action.payload};

        default:
            return state;
                
    }
};


export const getLevel2Data = (state, vocab) => {
    let data;
    switch (vocab) {
        case "CPT":
            data = state.cptTreeLevel2Data;
            break;
        case "ICD9":
            data = state.icd9TreeLevel2Data;
            break;
        case "ICD10":
            data = state.icd10TreeLevel2Data;
            break;
        case "ATC":
            data = state.atcTreeLevel2Data
            break;
        default:
            return null;
    }

    if (data.length === 0) {
        return []
    } else {
        return data
    }

}


export const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    );
};


