import { Box } from "@mui/system";
import React, { useContext } from "react";
import { TreeNode } from "./TreeNode";
import { LoadingElementLong } from "../utils/constants";
import { AppContext, getLevel2Data } from "../StateHandler/AppProvider";
import { useFetchWithCache } from "../utils/util";

export default function TreeVisualizationComponent() {
  const { state, dispatch } = useContext(AppContext);

  const { selectedCategory } = state;

  const { data: level1Data } = useFetchWithCache(
    `/api/trees/level/2`,
    state,
    dispatch,
    true
  );

  return (
    <Box
      component="div"
      sx={{ flexDirection: "column", display: "flex", p: 2, width: 1 }}
    >
      <div>
        {level1Data.length === 0 ? (
          <>{LoadingElementLong}</>
        ) : (
          <Box sx={{ p: 0, pt: 1 }}>
            {level1Data.map((node, index) => {
              return <TreeNode key={index} node={node} />;
            })}
          </Box>
        )}
      </div>
    </Box>
  );
}
