import React, { useContext } from "react";
import { Icon, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AppContext } from "../StateHandler/AppProvider";
import { useFetchChildren } from "../utils/util";
import { LOAD_ON_HOVER } from "../utils/constants";

export const ExpandedElement = ({ nodeKey, isExpanded, has_children }) => {
  const { state, dispatch } = useContext(AppContext);
  const { data: children, refetch } = useFetchChildren(
    nodeKey,
    state,
    dispatch
  );

  const toggleExpand = async () => {
    if (!has_children) {
      throw Error("Can't expand or contract node without children");
    }

    if (!isExpanded) {
      // Not currently expanded, will expand
      dispatch({ type: "ADD_EXPANDED_KEY", payload: nodeKey });
      if (!children) {
        // If children haven't been fetched yet, fetch them

        await refetch();
      }
    } else {
      // Currently Expanded, will collapse
      dispatch({ type: "REMOVE_EXPANDED_KEY", payload: nodeKey });
    }
  };

  const handleMouseEnter = () => {
    if (has_children && LOAD_ON_HOVER && !children) {
      refetch();
    }
  };

  let innerIcon;

  if (isExpanded && has_children) {
    innerIcon = <ExpandMoreIcon />;
  } else if (!has_children) {
    innerIcon = <Icon />;
  } else {
    innerIcon = <ChevronRightIcon />;
  }

  return (
    <IconButton
      sx={{ p: 0 }}
      onClick={toggleExpand}
      disabled={!has_children}
      onMouseEnter={handleMouseEnter}
    >
      {innerIcon}
    </IconButton>
  );
};
