import React, {Profiler} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Auth0Provider} from '@auth0/auth0-react';
import {AppProvider} from "./StateHandler/AppProvider";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();


root.render(
    <Profiler id="App">
            <QueryClientProvider client={queryClient}>


        <Auth0Provider
            domain="codenavigator.us.auth0.com"
            clientId="4gToTepjMfDvkqbDUa6KAKl40td91hUF"
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: `https://codenavigator.pike00.com/api/`
            }}
            scope="roles email openid profile"
        >
            <AppProvider>
                <App sx={{width: '100%', height: '100%'}}/>
            </AppProvider>

        </Auth0Provider>
        </QueryClientProvider>
    </Profiler>
);

