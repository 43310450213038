import { VOCABULARY_TREES } from "../utils/constants";
import TreeVisualizationComponent from "./TreeVisualizationComponent";
import { ButtonGroup, Grid, Typography } from "@mui/material";
import { AppContext } from "../StateHandler/AppProvider";
import { useContext } from "react";
import Button from "@mui/material/Button";

export function TreeVisualizationContainer() {
  const { state, dispatch } = useContext(AppContext);

  const { selectedCategory } = state;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="start"
      // spacing={0}
      width={1}
      height={"100vh"}
      sx={{ p: 2 }}
    >
      <Grid
        item
        // sx={{mx: 2, py: 2, mt: 2}}
      >
        <Typography variant={"h4"}>Tree Visualization</Typography>
      </Grid>

      <Grid item width={1} sx={{ py: 2 }}>
        {/*<Box sx={{display: "flex", justifyContent: "left"}}>*/}
        <ButtonGroup
          variant="text"
          aria-label="text button group"
          sx={{ width: "100%" }}
        >
          {[...VOCABULARY_TREES].map((category) => {
            return (
              <Button
                key={category}
                onClick={() =>
                  dispatch({
                    type: "SET_SELECTED_TREE_CATEGORY",
                    payload: category,
                  })
                }
                sx={{
                  flexGrow: 1,
                  color: selectedCategory === category ? "white" : "grey",
                  backgroundColor:
                    selectedCategory === category ? "#1976d2" : "transparent",
                  "&:hover": {
                    backgroundColor:
                      selectedCategory === category
                        ? "#115293"
                        : "rgba(0, 0, 0, 0.04)",
                  },
                  transition: "background-color 0.3s",
                }}
              >
                {category}
              </Button>
            );
          })}
        </ButtonGroup>

        {/*</Box>*/}
      </Grid>
      <Grid item xs={10} width={1} overflow={"auto"} sx={{ pr: 1 }}>
        <TreeVisualizationComponent vocabulary={selectedCategory} />
      </Grid>
    </Grid>
  );
}
