import React from "react";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';

export const url_base = "dev.codenavigator.pike00.com"

export const LoadingElementLong = (<Box sx={{ml: 2}}>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
</Box>);


export const LoadingElementShort = (<Box sx={{ml: 2}}>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
    <Skeleton animation="wave" width={"400px"}/>
</Box>);

export const VOCABULARY_TREES = ['CPT', 'ICD9', 'ICD10', 'ATC'];


export const FLAG_SETTINGS = [
    // {key: 'simplifyTQL', label: 'Simplify TQL?', enabled: true},
    // {key: 'unionTQL', label: 'Union TQL?', enabled: false},
    {key: 'convertToICD9', label: 'Include ICD9 Conversion?', enabled: false}
    // {key: 'generateSourceRx', label: 'Generate SOURCE_RX?', enabled: false}
];


export const TREE_VOCABULARIES = [
    "CPT",
    "ICD9",
    "ICD10",
    "ATC"
]

export const CACHE_DATA = false
export const LOAD_ON_HOVER = false